export const permissions = {
  'view-users': 'view-users',
  'create-users': 'create-users',
  'update-users': 'update-users',
  'delete-users': 'delete-users',
  'force_delete-users': 'force_delete-users',
  'view-roles': 'view-roles',
  'create-roles': 'create-roles',
  'update-roles': 'update-roles',
  'force_delete-roles': 'force_delete-roles',
  'update-entreprise': 'update-entreprise',
  'view-bc': 'view-bc',
  'create-bc': 'create-bc',
  'update-bc': 'update-bc',
  'force_delete-bc': 'force_delete-bc',
  'delete-bc': 'delete-bc',
  'print-bc': 'print-bc',
  'view-bl': 'view-bl',
  'create-bl': 'create-bl',
  'update-bl': 'update-bl',
  'force_delete-bl': 'force_delete-bl',
  'delete-bl': 'delete-bl',
  'print-bl': 'print-bl',
  'view-devis': 'view-devis',
  'create-devis': 'create-devis',
  'update-devis': 'update-devis',
  'force_delete-devis': 'force_delete-devis',
  'delete-devis': 'delete-devis',
  'print-devis': 'print-devis',
  'view-facture': 'view-facture',
  'create-facture': 'create-facture',
  'update-facture': 'update-facture',
  'force_delete-facture': 'force_delete-facture',
  'delete-facture': 'delete-facture',
  'print-facture': 'print-facture',
  'view-product': 'view-product',
  'create-product': 'create-product',
  'update-product': 'update-product',
  'force_delete-product': 'force_delete-product',
  'delete-product': 'delete-product',
  'view-category': 'view-category',
  'create-category': 'create-category',
  'update-category': 'update-category',
  'force_delete-category': 'force_delete-category',
  'delete-category': 'delete-category',
  'view-brand': 'view-brand',
  'create-brand': 'create-brand',
  'update-brand': 'update-brand',
  'force_delete-brand': 'force_delete-brand',
  'delete-brand': 'delete-brand',
  'view-warehouse': 'view-warehouse',
  'create-warehouse': 'create-warehouse',
  'update-warehouse': 'update-warehouse',
  'force_delete-warehouse': 'force_delete-warehouse',
  'delete-warehouse': 'delete-warehouse',
  'view-manufacturer': 'view-manufacturer',
  'create-manufacturer': 'create-manufacturer',
  'update-manufacturer': 'update-manufacturer',
  'force_delete-manufacturer': 'force_delete-manufacturer',
  'delete-manufacturer': 'delete-manufacturer',
  'view-supplier': 'view-supplier',
  'create-supplier': 'create-supplier',
  'update-supplier': 'update-supplier',
  'force_delete-supplier': 'force_delete-supplier',
  'delete-supplier': 'delete-supplier',
  'view-client': 'view-client',
  'create-client': 'create-client',
  'update-client': 'update-client',
  'force_delete-client': 'force_delete-client',
  'delete-client': 'delete-client',
}
