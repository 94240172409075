import './auth-wrapper.style.less'

import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'
import { Outlet } from 'react-router'

import AuthLayout from './AuthLayout'
import AppAnimateGroup from '../components/AppAnimateGroup'

const AuthWrapper = () => {
  return (
    <AuthLayout>
      <AppAnimateGroup
        type='scale'
        animateStyle={{ flex: 1 }}
        delay={0}
        interval={10}
        duration={200}
      >
        <Layout.Content className='main-content-view'>
          <Outlet />
        </Layout.Content>
      </AppAnimateGroup>
    </AuthLayout>
  )
}

export default React.memo(AuthWrapper)

AuthWrapper.propTypes = {
  children: PropTypes.node,
}
