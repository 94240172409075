import './index.style.less'

import React from 'react'
import { Spin } from 'antd'

const AppLoader = ({ ...props }) => {
  return (
    <div className='app-loader' {...props}>
      <Spin size='large' />
    </div>
  )
}

export default React.memo(AppLoader)
