import saMessages from '../locales/fr_FR.json'
import frFR from 'antd/lib/locale/fr_FR'
import 'moment/locale/fr'

const saLang = {
  messages: {
    ...saMessages,
  },
  antLocale: frFR,
  locale: 'fr-FR',
}
export default saLang
