import '../index.style.less'

import React from 'react'
import { Button } from 'antd'
import { ReactComponent as Logo } from '@/assets/icon/500.svg'
import AppAnimateGroup from '@/layouts/components/AppAnimateGroup'

export const Error500 = ({ error: { message, stack } }) => {
  return (
    <AppAnimateGroup type='bottom'>
      <div className='error-container' key='a'>
        <div className='error-img'>
          <Logo />
        </div>
        <div className='error-content'>
          <h3>Something went wrong</h3>
          <div className='error-para'>
            <p className='mb-0'>{message}</p>
            <p className='mb-0'>{stack}</p>
          </div>
          <Button type='primary' className='error-btn' href='/'>
            goBackToHome
          </Button>
        </div>
      </div>
    </AppAnimateGroup>
  )
}
