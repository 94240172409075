import 'simplebar/src/simplebar.css'
import './index.style.less'

import React from 'react'
import PropTypes from 'prop-types'
import SimpleBarReact from 'simplebar-react'

const AppScrollbar = ({ children, className, ...others }) => {
  return (
    <SimpleBarReact className={className} {...others}>
      {children}
    </SimpleBarReact>
  )
}

export default React.memo(AppScrollbar)

AppScrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
