import { QueryClient } from '@tanstack/react-query'
import axios from 'axios'

const url = process.env.REACT_APP_BACKEND_URL

export const BASE_URL = `${url}/v1`
export const IMAGE_BASE_URL = url

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      retry: false,
      networkMode: 'online',
    },
  },
})

export const apiClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: { 'X-Requested-With': 'XMLHttpRequest', Accept: 'application/json' },
})
