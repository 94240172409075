import { apiClient } from '@/shared/configs/api.config'
import axios from 'axios'

export class AuthService {
  config = {}
  cancellationToken = axios.CancelToken.source()

  static createInstance() {
    const activeInstance = new AuthService()
    activeInstance.cancellationToken = axios.CancelToken.source()
    activeInstance.config.cancelToken = activeInstance.cancellationToken.token
    return activeInstance
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation')
    return AuthService.createInstance()
  }

  login = (data) => apiClient.post('/auth/login', data).then((res) => res.data)

  verify = (data) => apiClient.post('/auth/verify', data).then((res) => res.data)

  me = () => apiClient.get('/auth/me').then((res) => res.data)

  logout = () => apiClient.post('/auth/logout').then((res) => res.data)
}
