import '../index.style.less'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { ReactComponent as Logo } from '@/assets/icon/404.svg'
import AppAnimateGroup from '@/layouts/components/AppAnimateGroup'
import { ROUTES } from '@/shared/constants/RoutesEnum'

const Error404 = () => {
  const navigate = useNavigate()

  const onGoBackToHome = () => navigate(ROUTES.HOME, { replace: true })

  return (
    <AppAnimateGroup type='bottom'>
      <div className='error-container' key='a'>
        <div className='error-img'>
          <Logo />
        </div>
        <div className='error-content'>
          <h3>404 Page not found</h3>
          <div className='error-para'>
            <p className='mb-0'>the page you're looking for doesn't exist</p>
          </div>
          <Button type='primary' className='error-btn' onClick={onGoBackToHome}>
            back to home
          </Button>
        </div>
      </div>
    </AppAnimateGroup>
  )
}

export default Error404
