import React from 'react'
import { Navigate } from 'react-router'

import { Loadable } from '../utils'
import { ROUTES } from '../constants/RoutesEnum'
import { Authorization, SuperAdminOnly } from '../guards'
import { permissions } from '../constants/PermissionsEnum'

const AppLayout = Loadable(() => import('@/layouts/App'))

const UserProfile = Loadable(() => import('@/pages/UserProfile'))
const RolesPage = Loadable(() => import('@/pages/Roles'))
const UsersPage = Loadable(() => import('@/pages/Users'))
const EntreprisePage = Loadable(() => import('@/pages/Entreprise'))

const CategoriesPage = Loadable(() => import('@/pages/Products/categories'))
const ProductsPage = Loadable(() => import('@/pages/Products/products'))
const BrandsPage = Loadable(() => import('@/pages/Products/brands'))
const WarehousesPage = Loadable(() => import('@/pages/Products/warehouses'))
const ManuFacturersPage = Loadable(() => import('@/pages/Products/manufacturers'))

const BCsPage = Loadable(() => import('@/pages/Documents/bc'))
const SellssPage = Loadable(() => import('@/pages/Documents/sells'))
const BlsPage = Loadable(() => import('@/pages/Documents/bl'))
const DevisPage = Loadable(() => import('@/pages/Documents/devis'))
const InvoicesPage = Loadable(() => import('@/pages/Documents/invoice'))

const SuppliersPage = Loadable(() => import('@/pages/Suppliers'))
const ClientsPage = Loadable(() => import('@/pages/Clients'))

const CaissePage = Loadable(() => import('@/pages/Caisse'))
const VentePage = Loadable(() => import('@/pages/Ventes'))

//
const SlidesPage = Loadable(() => import('@/services/slides'))
const OrdersPage = Loadable(() => import('@/services/shop/order'))
const ServicesPage = Loadable(() => import('@/services/shop/service'))
const AboutUsPage = Loadable(() => import('@/services/shop/about'))
const ContactPage = Loadable(() => import('@/services/shop/contact'))
const NewsLetterPage = Loadable(() => import('@/services/shop/newsletter'))
const SettingsPage = Loadable(() => import('@/services/shop/settings'))
const HomePage = Loadable(() => import('@/services/home'))

const RapportPage = Loadable(() => import('@/services/rapport'))

const forbiddenFallback = <Navigate to={ROUTES.HOME} replace={true} />

export const protectedRoutes = [
  { path: ROUTES.LOGIN, element: <Navigate to={ROUTES.HOME} replace={true} /> },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      // ecommerce
      {
        element: <HomePage />,
        path: '/',
        caseSensitive: true,
      },
      {
        element: <SlidesPage />,
        path: ROUTES.SLIDE.INDEX,
        caseSensitive: true,
      },
      {
        element: <RapportPage />,
        path: ROUTES.RAPPORT.INDEX,
        caseSensitive: true,
      },
      {
        element: <OrdersPage />,
        path: ROUTES.ORDER.INDEX,
        caseSensitive: true,
      },
      {
        element: <ServicesPage />,
        path: ROUTES.SERVICE.INDEX,
        caseSensitive: true,
      },
      {
        element: <AboutUsPage />,
        path: ROUTES.ABOUT_US.INDEX,
        caseSensitive: true,
      },
      {
        element: <ContactPage />,
        path: ROUTES.CONTACT_US.INDEX,
        caseSensitive: true,
      },
      {
        element: <NewsLetterPage />,
        path: ROUTES.NEWSLETTER.INDEX,
        caseSensitive: true,
      },
      {
        element: <SettingsPage />,
        path: ROUTES.SETTINGS.INDEX,
        caseSensitive: true,
      },

      //
      {
        element: <UserProfile />,
        path: ROUTES.PROFILE,
        caseSensitive: true,
      },
      {
        element: <CaissePage />,
        path: ROUTES.CAISSE.INDEX,
        caseSensitive: true,
      },
      {
        element: <VentePage />,
        path: ROUTES.VENTE.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<InvoicesPage />}
            allowedPermissions={[
              permissions['view-facture'],
              permissions['create-facture'],
              permissions['update-facture'],
              permissions['print-facture'],
              permissions['delete-facture'],
              permissions['force_delete-facture'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.INVOICE.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<DevisPage />}
            allowedPermissions={[
              permissions['view-devis'],
              permissions['create-devis'],
              permissions['update-devis'],
              permissions['print-devis'],
              permissions['delete-devis'],
              permissions['force_delete-devis'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.DEVIS.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<BlsPage />}
            allowedPermissions={[
              permissions['view-bl'],
              permissions['create-bl'],
              permissions['update-bl'],
              permissions['print-bl'],
              permissions['delete-bl'],
              permissions['force_delete-bl'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.BL.INDEX,
        caseSensitive: true,
      },
      {
        element: <SuperAdminOnly children={<SellssPage />} forbiddenFallback={forbiddenFallback} />,
        path: ROUTES.SELLS.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<ManuFacturersPage />}
            allowedPermissions={[
              permissions['view-manufacturer'],
              permissions['create-manufacturer'],
              permissions['update-manufacturer'],
              permissions['delete-manufacturer'],
              permissions['force_delete-manufacturer'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.MANUFACTURER.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<WarehousesPage />}
            allowedPermissions={[
              permissions['view-warehouse'],
              permissions['create-warehouse'],
              permissions['update-warehouse'],
              permissions['delete-warehouse'],
              permissions['force_delete-warehouse'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.WAREHOUSE.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<ProductsPage />}
            allowedPermissions={[
              permissions['view-product'],
              permissions['create-product'],
              permissions['update-product'],
              permissions['delete-product'],
              permissions['force_delete-product'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.PRODUCT.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<BrandsPage />}
            allowedPermissions={[
              permissions['view-brand'],
              permissions['create-brand'],
              permissions['update-brand'],
              permissions['delete-brand'],
              permissions['force_delete-brand'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.BRAND.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<CategoriesPage />}
            allowedPermissions={[
              permissions['view-facture'],
              permissions['create-facture'],
              permissions['update-facture'],
              permissions['delete-facture'],
              permissions['force_delete-facture'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.CATEGORY.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<SuppliersPage />}
            allowedPermissions={[
              permissions['view-supplier'],
              permissions['create-supplier'],
              permissions['update-supplier'],
              permissions['delete-supplier'],
              permissions['force_delete-supplier'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.SUPPLIER.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<ClientsPage />}
            allowedPermissions={[
              permissions['view-client'],
              permissions['create-client'],
              permissions['update-client'],
              permissions['delete-client'],
              permissions['force_delete-client'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.CLIENT.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<BCsPage />}
            allowedPermissions={[
              permissions['view-bc'],
              permissions['create-bc'],
              permissions['update-bc'],
              permissions['print-bc'],
              permissions['delete-bc'],
              permissions['force_delete-bc'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.BC.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<RolesPage />}
            allowedPermissions={[
              permissions['view-roles'],
              permissions['create-roles'],
              permissions['update-roles'],
              permissions['force_delete-roles'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.ROLES.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<UsersPage />}
            allowedPermissions={[
              permissions['view-users'],
              permissions['create-users'],
              permissions['update-users'],
              permissions['delete-users'],
              permissions['force_delete-users'],
            ]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.USERS.INDEX,
        caseSensitive: true,
      },
      {
        element: (
          <Authorization
            children={<EntreprisePage />}
            allowedPermissions={[permissions['update-entreprise']]}
            forbiddenFallback={forbiddenFallback}
          />
        ),
        path: ROUTES.ENTREPRISE,
        caseSensitive: true,
      },
    ],
  },
]
