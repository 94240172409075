import React from 'react'
// import { useNetworkState } from 'react-use'
import { ErrorBoundary } from 'react-error-boundary'
import { ConfigProvider } from 'antd'
import { QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import { AppRoutes } from './shared/routes'
import { AuthProvider } from '@/shared/context/auth-context'
import defaultConfig from '@/shared/configs/theme.config'
import { queryClient } from '@/shared/configs/api.config'
import AppLocale from '@/shared/localization'
import { Error500 } from './pages/errorPages/Error500'

const App = () => {
  const antLocal = AppLocale[defaultConfig.locale.locale]
  return (
    <ErrorBoundary FallbackComponent={Error500}>
      <Router>
        <ConfigProvider locale={antLocal.antLocale}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </QueryClientProvider>
        </ConfigProvider>
      </Router>
    </ErrorBoundary>
  )
}

export default App
