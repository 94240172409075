const routePrefix = '/pos'
const ecomRoutePrefix = '/ecom'

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  VERIFY_OTP: '/verify-otp',
  PROFILE: '/my-profile',
  ENTREPRISE: `${routePrefix}/entreprise/info`,
  ROLES: { INDEX: `${routePrefix}/roles` },
  USERS: { INDEX: `${routePrefix}/users`, ARCHIVED: `${routePrefix}/users/archived` },

  // ecom
  SLIDE: { INDEX: `${routePrefix}${ecomRoutePrefix}/slides` },
  SERVICE: { INDEX: `${routePrefix}${ecomRoutePrefix}/services` },
  ORDER: { INDEX: `${routePrefix}${ecomRoutePrefix}/orders` },
  ABOUT_US: { INDEX: `${routePrefix}${ecomRoutePrefix}/about-us` },
  CONTACT_US: { INDEX: `${routePrefix}${ecomRoutePrefix}/contact-us` },
  NEWSLETTER: { INDEX: `${routePrefix}${ecomRoutePrefix}/newsletter` },
  SETTINGS: { INDEX: `${routePrefix}${ecomRoutePrefix}/settings` },

  //
  CAISSE: { INDEX: `${routePrefix}/caisse` },

  VENTE: { INDEX: `${routePrefix}/ventes` },
  RAPPORT: { INDEX: `${routePrefix}/rapports` },

  BC: {
    INDEX: `${routePrefix}/bon-de-commandes`,
    ARCHIVED: `${routePrefix}/bon-de-commandes/archived`,
  },

  BL: {
    INDEX: `${routePrefix}/bon-de-livraison`,
    ARCHIVED: `${routePrefix}/bon-de-livraison/archived`,
  },

  DEVIS: { INDEX: `${routePrefix}/devis`, ARCHIVED: `${routePrefix}/devis/archived` },

  INVOICE: { INDEX: `${routePrefix}/factures`, ARCHIVED: `${routePrefix}/factures/archived` },

  SELLS: { INDEX: `${routePrefix}/ventes` },

  //
  PRODUCT: {
    INDEX: `${routePrefix}/produits/produits`,
    ARCHIVED: `${routePrefix}/produits/archived`,
  },

  BRAND: { INDEX: `${routePrefix}/produits/brands`, ARCHIVED: `${routePrefix}/brands/archived` },

  CATEGORY: {
    INDEX: `${routePrefix}/produits/categories`,
    ARCHIVED: `${routePrefix}/categories/archived`,
  },

  WAREHOUSE: {
    INDEX: `${routePrefix}/produits/warehouses`,
    ARCHIVED: `${routePrefix}/warehouses/archived`,
  },

  MANUFACTURER: {
    INDEX: `${routePrefix}/produits/manufacturers`,
    ARCHIVED: `${routePrefix}/manufacturer/archived`,
  },

  //
  CLIENT: { INDEX: `${routePrefix}/clients`, ARCHIVED: `${routePrefix}/clients/archived` },

  SUPPLIER: {
    INDEX: `${routePrefix}/fournisseures`,
    ARCHIVED: `${routePrefix}/fournisseures/archived`,
  },

  NOT_FOUND: '/error-404',
}
