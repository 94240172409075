import Error404 from '@/pages/errorPages/Error404'
import { Navigate, useRoutes } from 'react-router'
import { ROUTES } from '../constants/RoutesEnum'
import { Loadable } from '../utils'
import { protectedRoutes } from './protected'
import AuthWrapper from '@/layouts/Auth'
import { useAuth } from '../context/auth-context'

const Login = Loadable(() => import('@/pages/auth/Login'))
const VerifyOTP = Loadable(() => import('@/pages/auth/VerifyOTP'))

const publicRoutes = [
  {
    path: '/',
    element: <AuthWrapper />,
    children: [
      { path: '/', element: <Navigate to={ROUTES.LOGIN} replace={true} /> },
      { path: ROUTES.LOGIN, element: <Login /> },
      { path: ROUTES.VERIFY_OTP, element: <VerifyOTP /> },
    ],
  },
]

export const AppRoutes = () => {
  const { user } = useAuth()

  const commonRoutes = [{ path: '*', element: <Error404 /> }]

  const routes = user ? protectedRoutes : publicRoutes

  const element = useRoutes([...routes, ...commonRoutes])

  return element
}
