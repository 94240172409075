import AppLoader from '@/layouts/components/AppLoader'
import { message } from 'antd'
import { lazy, Suspense } from 'react'
import * as XLSX from 'xlsx/xlsx.mjs'

export const getBreakPointsValue = (valueSet, breakpoint) => {
  if (typeof valueSet === 'number') return valueSet
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs
    case 'sm':
      return valueSet.sm || valueSet.xs
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
    default:
      return valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
  }
}

export const getFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes'
  let k = 1024,
    dm = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const formatMoney = (sold = 0) => {
  return sold.toLocaleString('fr-FR', { style: 'currency', currency: 'MAD' })
}

export const multiPropsFilter = (products, filters, stringKey = 'title') => {
  const filterKeys = Object.keys(filters)
  return products.filter((product) => {
    return filterKeys.every((key) => {
      if (!filters[key].length) return true
      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some((keyEle) => filters[key].includes(keyEle))
      }
      if (key === stringKey) {
        return product[key].toLowerCase().includes(filters[key].toLowerCase())
      }
      return filters[key].includes(product[key])
    })
  })
}

export const Loadable = (factory) => (props) => {
  const Component = lazy(() => factory())
  return (
    <Suspense fallback={<AppLoader />}>
      <Component {...props} />
    </Suspense>
  )
}

export const flattenObject = (obj) => {
  const flattened = {}
  for (let key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const flatObject = flattenObject(obj[key])
      for (let flatKey in flatObject) {
        flattened[key + '.' + flatKey] = flatObject[flatKey]
      }
    } else {
      flattened[key] = obj[key]
    }
  }
  return flattened
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const menuItem = ({ checkAccess, label, ...props }) => {
  if (!checkAccess) return { label, ...props }

  return checkAccess() ? { label, ...props } : undefined
}

export const jsonToXlsx = ({
  data,
  fileName = 'default.xlsx',
  sheetName = 'Default Sheet',
  propsToKeep = [],
  propsToRemove = [],
}) => {
  if (!data) return message.error('No Data')

  const wb = XLSX.utils.book_new()

  let filteredObjects = data.map((object) => {
    const keptKeys = Object.keys(object).filter((key) => {
      return (
        propsToKeep
          .map((p) => {
            const _value = p.value.toString()

            if (_value.includes('.')) return _value.split('.')[0]
            if (_value.includes('[]')) return _value.split('[]')[0]

            return _value
          })
          .includes(key) && !propsToRemove.includes(key)
      )
    })

    return keptKeys.reduce((newObject, key) => {
      newObject[key] = object[key]
      return newObject
    }, {})
  })

  filteredObjects = filteredObjects.map((item) => {
    let newItem = {}
    Object.keys(item).forEach((k) => {
      const _key = propsToKeep.find((p) => {
        const _value = p.value.toString()

        return !_value.includes('.') && !_value.includes('[]') && _value === k
      })

      const complexKey = propsToKeep.find((p) => {
        const _value = p.value.toString()

        return (
          (_value.includes('.') && _value.split('.')[0] === k) ||
          (_value.includes('[]') && _value.split('[]')[0] === k)
        )
      })

      if (!!_key) {
        newItem[_key.label] = item[k]
      }

      if (!!complexKey) {
        const _value = String(complexKey.value)
        if (_value.includes('.')) {
          const keys = _value.split('.')

          if (item[keys[0]] && item[keys[0]][keys[1]]) {
            newItem[complexKey.label] = item[keys[0]] && item[keys[0]][keys[1]]
          }
        }
        if (_value.includes('[]')) {
          newItem[complexKey.label] = item['products']
            .map((p) => `${p.qty} - ${p.product.name} - ${p.price}`)
            .join('\n')
        }
      }
    })

    return newItem
  })

  const ws = XLSX.utils.json_to_sheet(filteredObjects)
  const date = new Date().toLocaleString().replaceAll(' ', '-').toLowerCase()
  XLSX.utils.book_append_sheet(wb, ws, sheetName)
  XLSX.writeFile(wb, `${date}_${fileName}`)
}
