import './auth-layout.style.less'

import React from 'react'
import { Layout } from 'antd'

import AppScrollbar from '../components/AppScrollbar'

const AuthLayout = ({ children }) => {
  return (
    <Layout className='auth'>
      <AppScrollbar className='main-auth-scrollbar' children={children} />
    </Layout>
  )
}

export default React.memo(AuthLayout)
