import enLang from './entries/en-US'
import zhLang from './entries/zh-Hans-CN'
import arLang from './entries/ar_SA'
import itLang from './entries/it_IT'
import esLang from './entries/es_ES'
import frLang from './entries/fr_FR'

const AppLocale = { en: enLang, zh: zhLang, ar: arLang, it: itLang, es: esLang, fr: frLang }

export default AppLocale
