import useSWR from 'swr'
import { message } from 'antd'
import { useMutation } from '@tanstack/react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import React, { createContext, useState, useContext } from 'react'
import { AuthService } from '@/services/auth/auth.service'
import AppLoader from '@/layouts/components/AppLoader'
import { ROUTES } from '../constants/RoutesEnum'

export const AuthContext = createContext()
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [tmp, setTmp] = useState(null)
  const [loading, setLoading] = useState(true)

  const { data: user, mutate } = useSWR('/auth/me', AuthService.createInstance().me, {
    onSuccess: (_user) => {
      setLoading(false)
    },
    onError: () => setLoading(false),
    onErrorRetry: (error) => {
      if (
        (error.response?.status === 401 || error.response?.status === 404) &&
        ![ROUTES.LOGIN, ROUTES.VERIFY_OTP].includes(location.pathname)
      ) {
        mutate(null)
        return navigate(ROUTES.LOGIN, { replace: true })
      }
    },
  })

  const loginMutation = useMutation(AuthService.createInstance().login, {
    onSuccess: (result, data) => {
      message.info('Vérification à double facteur')
      setTmp({ ...result, ...data })
      return navigate(ROUTES.VERIFY_OTP, { replace: true })
    },
    onError: (error) => message.error(error.response.data.message),
  })

  const verifyLoginMutation = useMutation(AuthService.createInstance().verify, {
    onMutate: () => setLoading(true),
    onSuccess: () => {
      setTmp(null)
      mutate()
      return navigate(ROUTES.HOME, { replace: true })
    },
    onError: (error) => message.error(error.response.data.message),
  })

  const logoutMutation = useMutation(AuthService.createInstance().logout, {
    onSuccess: () => {
      mutate(null)
      return navigate(ROUTES.LOGIN, { replace: true })
    },
  })

  const logout = async () => {
    await logoutMutation.mutateAsync()
  }

  const values = { loginMutation, verifyLoginMutation, logoutMutation, user, tmp, logout, mutate }

  const child =
    verifyLoginMutation.isLoading || loginMutation.isLoading || loading ? <AppLoader /> : children

  return <AuthContext.Provider value={values} children={child} />
}
export const useAuth = () => useContext(AuthContext)
