import { useCallback } from 'react'
import { useAuth } from '../context/auth-context'

export const useAuthorization = () => {
  const { user } = useAuth()

  if (!user) throw Error('User does not exist!')

  const checkAccess = useCallback(
    ({ allowedPermissions }) => {
      if (allowedPermissions && allowedPermissions.length > 0) {
        return !!user.role.permissions.find((p) => allowedPermissions.includes(p.name))
      }
      return true
    },
    [user.role]
  )

  const isSuperAdmin = user.role?.name === 'superadmin'

  return { checkAccess, isSuperAdmin, role: user.role }
}

export const Authorization = ({ allowedPermissions, forbiddenFallback = null, children }) => {
  const { checkAccess } = useAuthorization()

  let canAccess = false

  if (allowedPermissions) canAccess = checkAccess({ allowedPermissions })

  return canAccess ? children : forbiddenFallback
}

export const SuperAdminOnly = ({ forbiddenFallback = null, children }) => {
  const { role } = useAuthorization()

  let canAccess = false

  if (role?.name === 'superadmin') canAccess = true

  return canAccess ? children : forbiddenFallback
}
